import classNames from "classnames"
import React, { useState, useCallback } from "react"

import { Color } from "../../../../constants/V2/color"
import CircledIconButton from "../../../elements/V2/Buttons/CircledIconButton"
import GatsbyStoryblokImage from "../../../elements/V2/GatsbyStoryblokImage"
import Typography from "../../../elements/V2/Typography"
import Video from "../../../elements/V2/Video"

import { useScrollAnimateSelf } from "@hooks/V2/useScrollAnimate"
import { getAccentColor, getBackgroundColorClass } from "@utils/V2/color"

type BaseProps = {
  backgroundVideo?: string
  backgroundImage: string
  backgroundImageAlt: string
  type: "imageWithIcon" | "bottomImageOverlay" | "callToAction"
}

type ImageProps = BaseProps &
  Partial<{
    // type: "imageWithIcon"
    icon?: string
    iconAlt?: string
  }>

type BottomImageOverlayProps = BaseProps &
  Partial<{
    // type: "bottomImageOverlay"
    overlayImage: string
    overlayImageAlt: string
  }>

type CallToActionProps = BaseProps &
  Partial<{
    // type: "callToAction"
    accentColor: Color
    superscriptText?: string
    titleText: string
    descriptionText: string
    buttonLink: string
  }>

type Props = ImageProps & BottomImageOverlayProps & CallToActionProps

const RichMediaMaster = ({
  backgroundVideo,
  backgroundImage,
  backgroundImageAlt,
  type,
  icon,
  iconAlt,
  overlayImage,
  overlayImageAlt,
  accentColor,
  superscriptText,
  titleText,
  descriptionText,
  buttonLink,
  ...props
}: Props) => {
  const { animationRefCallback } = useScrollAnimateSelf(true)
  const [videoContainerHover, setVideoContainerHover] = useState(false)

  const onMouseEnter = useCallback(() => {
    setVideoContainerHover(true)
  }, [])

  const onMouseLeave = useCallback(() => {
    setVideoContainerHover(false)
  }, [])

  return (
    <div
      ref={animationRefCallback}
      onMouseEnter={backgroundVideo ? onMouseEnter : undefined}
      onMouseLeave={backgroundVideo ? onMouseLeave : undefined}
      className={classNames(
        "relative col-span-full grid grid-cols-1 sm:col-span-2 md:col-span-3 lg:col-span-6"
      )}
      {...props}
    >
      {backgroundVideo ? (
        <Video
          video={backgroundVideo}
          coverImage={backgroundImage}
          ImageAlt={backgroundImageAlt}
          aspectRatio="1:1"
          playbackMode="hover"
          videoContainerHover={videoContainerHover}
          className="rounded-lg h-full w-auto"
          rounded={true}
        />
      ) : (
        <GatsbyStoryblokImage
          aspectRatio="1:1"
          width={600}
          image={backgroundImage}
          alt={backgroundImageAlt || "Rich Media background image"}
          className="rounded-lg"
        />
      )}
      {type === "imageWithIcon" && icon && iconAlt && (
        <GatsbyStoryblokImage
          image={icon}
          alt={iconAlt || "Rich media icon"}
          className="absolute left-20 top-20 w-1/3 md:left-24 md:top-24 lg:left-32 lg:top-32"
        />
      )}
      {type === "bottomImageOverlay" && overlayImage && overlayImageAlt && (
        <div className="absolute -bottom-32 left-0 md:-bottom-48 lg:-bottom-[60px]">
          <GatsbyStoryblokImage
            aspectRatio="16:9"
            image={overlayImage}
            alt={overlayImageAlt || "Rich media overlay image"}
            width={375}
          />
        </div>
      )}
      {type === "callToAction" &&
        accentColor &&
        titleText &&
        descriptionText && (
          <div
            className={classNames(
              "p-lg absolute left-0 top-0 flex h-full w-full flex-col",
              superscriptText ? "justify-between" : "justify-end"
            )}
          >
            {superscriptText && (
              <Typography
                font="grotesk"
                weight="medium"
                size="subscript-lg"
                color={accentColor}
                text={superscriptText}
                className="uppercase"
              />
            )}

            <div
              className={classNames(
                getBackgroundColorClass(accentColor),
                "flex transform-gpu items-start justify-between gap-24 rounded-16 bg-opacity-50 p-20 backdrop-blur lg:gap-32 lg:rounded-20 lg:p-24"
              )}
            >
              <div>
                <Typography
                  font="grotesk"
                  weight="medium"
                  size="lead-lg"
                  color={getAccentColor(accentColor)}
                  text={titleText}
                  className="mb-8"
                />
                <Typography
                  font="grotesk"
                  weight="book"
                  size="body-lg"
                  color={getAccentColor(accentColor)}
                  text={descriptionText}
                />
              </div>
              <CircledIconButton
                color={getAccentColor(accentColor)}
                linkUrl={buttonLink}
              />
            </div>
          </div>
        )}
    </div>
  )
}

export default RichMediaMaster
